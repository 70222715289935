import React from 'react'
import { Link, graphql } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/Layout'

import moment from 'moment'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMySqlResults.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Jester King Brewery Blog Archive" />
        {posts.map(({ node }) => {
          const ID = node.ID
          const title = node.Title
          const slug = node.url_title
          const posted = moment(node.Posted).format("MMMM D, YYYY")

          return (
            <div key={ID} className="post-box">
              <h3>
                <Link
                  to={slug}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </h3>
              <small>{posted}</small>
            </div>
          )
        })}
        <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
            listStyle: 'none',
            padding: 0,
          }}
        >
          <li>
            {!isFirst && (
              <Link to={prevPage} rel="prev">
                ← Previous Page
              </Link>
            )}
          </li>
          <li>
            {!isLast && (
              <Link to={nextPage} rel="next">
                Next Page →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMySqlResults(
      filter: {
        Section: {
          eq: "blog"
        }
        url_title: {
          ne: ""
        }
        Status: {
          eq: 4
        }
      }
      skip: $skip
      limit: $limit
    ) {
      totalCount,
      edges {
        node {
          ID
          Status
          Section
          Title
          url_title
          Posted
          Body_html
        }
      }
    }
  }
`
